<template>
    <section>
        <modal ref="modalVerMas" :titulo="`Productos más comprados en cuanto a ${relacion}`" tamano="modal-lg" no-aceptar>
            <div class="row mx-0 justify-center f-17 text-muted2 f-600">
                Durante:
                <div v-for="(data, i) in duracion" :key="i" class="col-auto px-0 mx-1">
                    {{ data.fecha }}
                    <span v-show="duracion.length - 1 != i">
                        -
                    </span>
                </div>
            </div>
            <div v-for="(producto,p) in productos" :key="p" class="row mx-0 mt-2 px-0 justify-center">
                <div class="col-auto">
                    <img :src="producto.foto_firmada" class="border br-12" style="width:50px;height:50px;object-fit:cover;" alt="" />
                </div>
                <div class="col-8">
                    <p class="mb-0 f-15 f-600 text-muted2">
                        {{ producto.nombre }}
                    </p>
                    <div class="row">
                        <div class="col-auto f-14">
                            {{ params.tipo == 1 ? separadorNumero(producto.valor,2) : agregarSeparadoresNumero(producto.valor,2) }}
                        </div>
                        <div class="col-auto ml-auto f-18 f-600 text-blue-modal">
                            {{ producto.porcentaje }}%
                        </div>
                    </div>
                    <div class="row" style="height:10px;">
                        <div class="h-100 bg-general br-5" :style="`width:${producto.porcentaje_max}%`" />
                    </div>
                </div>
            </div>
        </modal>
    </section>
</template>
<script>
import Metricas from "~/services/metricas_admin"

export default {
    props:{
        relacion: {
            type: String,
            default: () => { return '' }
        },
        duracion: {
            type: Array,
            default: () => { return []}
        }
    },
    data(){
        return{
            productos: [],
            params: {},
        }
    },
    methods: {
        toggle(params){
            this.params = params
            this.ver_mas_productos()
            this.$refs.modalVerMas.toggle();
        },
        async ver_mas_productos(){
            try {
                const { data } = await Metricas.ver_mas_productos(this.params)
                this.productos = data.data.productos

            } catch (error){

            }
        },
    }
}
</script>
